import React from "react";
import Layout from "gatsby-plugin-based-on-staticfuse/src/components/Layout";
import SEO from "gatsby-plugin-based-on-staticfuse/src/components/SEO";
import {
  Box,
  Checkbox,
  Text,
  Heading,
  Stack,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/core";
import { Link } from "gatsby";

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gpdrChecked: false,
    };
  }

  isValid() {
    console.log("this.state.gpdrChecked", this.state.gpdrChecked);
    return this.state.gpdrChecked === true;
  }

  render() {
    return (
      <Layout location={{ location: this.props.location }} fullWidth="true">
        <SEO title="Contact" />
        <Box maxW="3xl" m="auto" py={4} my={4} px={2}>
          <Heading as="h1" fontWeight="600" size="xl" lineHeight="1.1" my={8}>
            Contact us
          </Heading>
          <Text color="gray.400" fontWeight="500" fontSize="md">
            For further information and questions, please contact us by
            providing your contact details
          </Text>

          <form
            name="contact"
            method="post"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <Stack spacing={3}>
              <input type="hidden" name="form-name" value="contact" />
              <input style={{ display: "none" }} name="bot-field" />
              <Input placeholder="Name" name="name" />
              <Input placeholder="Email" name="email" />
              <Textarea placeholder="Message" name="message" />
              <Checkbox
                isChecked={this.state.gpdrChecked}
                onChange={(e) => {
                  console.log(e.target.checked);
                  this.setState({ gpdrChecked: e.target.checked });
                }}
              >
                I have read and agree to the{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>.
              </Checkbox>
              <Button
                disabled={!this.state.gpdrChecked}
                type="submit"
                onClick={(event) => {
                  if (!this.isValid()) {
                    console.log("Not valid");
                    event.preventDefault();
                    return;
                  }
                }}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Box>
      </Layout>
    );
  }
}
